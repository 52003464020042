import classNames from "classnames";
import Link from "next/link";
import {useEffect} from "react";
import {useState} from "react";
import {Nav, NavDropdown} from "react-bootstrap";
// import useGlobalHeaderContent from "../../lib/ButterCMSContent/useGlobalHeaderContent";
import {checkIfMobileDevice} from "../hooks/useIsMobileDevice";
import navItems from "../../public/content/header-nav-items.json";
import styles from "./NavbarNav.module.scss";
import {useRouter} from "next/router";
import {useFlags} from "launchdarkly-react-client-sdk";

function NavbarNavDropdown({children, menu_item}) {
  const {pathname} = useRouter();
  const [show, setShow] = useState(false);

  useEffect(() => {
    const isNotMobileDevice = !checkIfMobileDevice();
    let timeout;

    if (show && isNotMobileDevice) {
      timeout = setTimeout(() => {
        setShow(false);
      }, 3000);
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [show]);

  function handleOnMouseOver() {
    if (!show) {
      setShow(true);
    }
  }

  function handleOnToggle(nextShow) {
    setShow(nextShow);
  }

  return (
    <NavDropdown
      className={styles.navDropdown}
      onMouseOver={handleOnMouseOver}
      onToggle={handleOnToggle}
      show={show}
      title={menu_item}
    >
      {children.map(({menu_item, url}) => (
        <Link
          key={url}
          href={url}
          passHref
          prefetch={false}
          legacyBehavior={true}
        >
          <NavDropdown.Item
            active={url === pathname}
            className={styles.navDropdownItem}
          >
            {menu_item}
          </NavDropdown.Item>
        </Link>
      ))}
    </NavDropdown>
  );
}

export default function NavbarNav() {
  const {pathname} = useRouter();
  const featureFlags = useFlags();
  // const {navItems} = useGlobalHeaderContent(navContent);

  return (
    <Nav className={classNames("ms-lg-auto", styles.nav)}>
      {navItems.map((navItem) => {
        const {
          feature_flag,
          hasChildren,
          is_mobile_only,
          menu_item,
          mobile_menu_item,
          url,
        } = navItem;

        if (feature_flag && !featureFlags[feature_flag]) {
          return null;
        }

        if (hasChildren) {
          return <NavbarNavDropdown key={menu_item} {...navItem} />;
        }

        return (
          <Link
            key={url}
            href={url}
            passHref
            prefetch={false}
            legacyBehavior={true}
          >
            <Nav.Link
              className={classNames(styles.navItem, {
                "d-block d-lg-none": is_mobile_only,
              })}
              active={pathname === url}
            >
              {mobile_menu_item ? (
                <>
                  <span className="d-inline d-lg-none">{mobile_menu_item}</span>
                  <span className="d-none d-lg-inline">{menu_item}</span>
                </>
              ) : (
                <span>{menu_item}</span>
              )}
            </Nav.Link>
          </Link>
        );
      })}
    </Nav>
  );
}
