import Image from "next/legacy/image";
import Link from "next/link";
import {Navbar} from "react-bootstrap";
import logoLight from "../../public/images/logos/logoLight.svg";
import logoDark from "../../public/images/logos/logoDark.svg";
import {TYPE_THEME} from "../../lib/constants";
import styles from "./NavbarBrand.module.scss";

export default function NavbarBrand({theme}) {
  const imageSrc = ((theme) => {
    switch (theme) {
      case TYPE_THEME.TRANSPARENT:
      case TYPE_THEME.DARK:
        return logoDark;
      case TYPE_THEME.BLUE:
        return logoDark;
      default:
        return logoLight;
    }
  })(theme);

  return (
    <Link href="/" passHref prefetch={false} legacyBehavior={true}>
      <Navbar.Brand className={styles.logoContainer}>
        <Image
          alt="Autonomy Logo"
          height={30}
          priority
          src={imageSrc}
          width={139}
        />
      </Navbar.Brand>
    </Link>
  );
}
